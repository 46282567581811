import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { connect } from 'react-redux';
import dynamic from 'next/dynamic';
import {
  searchItemsQuery,
  searchVippedItemsQuery,
  searchVippedResidencesItemsQuery
} from '../../../queries';
import SearchPopup from '../../Search/SearchPopup';
import FallbackView from '../../FallbackView';
import SearchFilters from '../../Search/SearchFilters';
import Section from '../../Section';
import SectionList from '../../SectionList';
import NoResultsIcon from '../../../public/static/images/search/no-results.svg';
import ItemCard from '../../Cards/ItemCard';
import SectionPlaceholder from '../../Placeholders/SectionPlaceholder';
import ItemCardPlaceholder from '../../Placeholders/ItemCardPlaceholder';
import SearchToolsPlaceholder from '../../Placeholders/SearchToolsPlaceholder';
import withEndlessScroll from '../../HOC/endlessScroll';
import { setError } from '../../../actions';

const SearchTools = dynamic(() => import('../../Search/SearchTools'), {
  loading: SearchToolsPlaceholder,
  ssr: false
});

const searchListRef = React.createRef();
const EndlessSearchItems = withEndlessScroll(SectionList);

const mapDispatchToProps = dispatch => {
  return {
    setErrorUI: errorState => dispatch(setError(errorState))
  };
};

const convertValues = values => {
  return {
    cityId: values.cityId,
    categoryId: values.categoryId,
    leased: values.leased,
    roomIds: values.roomIds,
    priceFrom: values.priceFrom,
    priceTo: values.priceTo,
    areaFrom: values.areaFrom,
    areaTo: values.areaTo,
    landAreaFrom: values.landAreaFrom,
    landAreaTo: values.landAreaTo,
    paidDaily:
      typeof values.paidDaily !== 'undefined'
        ? [`${values.paidDaily}`]
        : undefined,
    onlyResidences: values.onlyResidences,
    hasBillOfSale: values.hasBillOfSale,
    hasMortgage: values.hasMortgage,
    hasRepair:
      typeof values.hasRepair !== 'undefined'
        ? `${values.hasRepair}`
        : undefined,
    floorFrom: values.floorFrom,
    floorTo: values.floorTo,
    floorFirst:
      typeof values.floorFirst !== 'undefined' ? !values.floorFirst : undefined,
    floorLast:
      typeof values.floorLast !== 'undefined'
        ? `${values.floorLast}`
        : undefined,
    locationIds: values.locationIds,
    buildingType: values.buildingType
  };
};

const SearchPage = ({ transformedObj, h1, setErrorUI }) => {
  const { t } = useTranslation();
  const [isPopupShown, setIsPopupShown] = useState(false);
  const togglePopup = () => {
    setIsPopupShown(!isPopupShown);
  };
  const [itemId, setItemId] = useState(null);
  const setCarousel = id => {
    return itemId === id ? null : setItemId(id);
  };

  const searchItemsQueryVariables = {
    first: 16,
    filter: transformedObj.filter,
    sort: transformedObj.sorting || 'BUMPED_AT_DESC'
  };
  const searchVippedQueryVariables = {
    filter: { ...transformedObj.filter, scope: 'VIPPED_PURE' },
    limit: 4,
    sort: 'RANDOM'
  };
  const searchVippedResidencesQueryVariables = {
    filter: { ...transformedObj.filter, scope: 'VIPPED_APARTMENTS' },
    limit: 4,
    sort: 'RANDOM'
  };
  const emptyLayout = (
    <FallbackView
      Icon={NoResultsIcon}
      width="102px"
      height="63px"
      text={t('search.no_results.text')}
      title={t('search.nothing_found')}
      btnText={t('search.no_results.link')}
      onClickHandler={togglePopup}
    />
  );

  /*
   * Load  data via hooks
   * */
  const [
    {
      loading: searchItemsQueryLoading,
      data: searchItemsQueryData,
      error: searchItemsError,
      fetchMore
    },
    {
      loading: searchVippedItemsQueryLoading,
      data: searchVippedItemsQueryData,
      error: searchVippedItemsQueryError
    },
    {
      loading: searchVippedResidencesItemsQueryLoading,
      data: searchVippedResidencesItemsQueryData,
      error: searchVippedResidencesItemsQueryError
    }
  ] = (() => {
    const searchQuery = useQuery(searchItemsQuery(searchItemsQueryVariables), {
      variables: searchItemsQueryVariables,
      ssr: false,
      notifyOnNetworkStatusChange: true
    });
    const searchVippedQuery = useQuery(
      searchVippedItemsQuery(searchVippedQueryVariables),
      {
        variables: searchVippedQueryVariables,
        ssr: false,
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true
      }
    );
    const searchVippedResidencesQuery = useQuery(
      searchVippedResidencesItemsQuery(searchVippedResidencesQueryVariables),
      {
        variables: searchVippedResidencesQueryVariables,
        ssr: false,
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true
      }
    );

    return [searchQuery, searchVippedQuery, searchVippedResidencesQuery];
  })();

  /*
   * searchItems
   * */

  const itemsList =
    searchItemsQueryData?.itemsConnection?.edges.map(item => {
      return (
        <ItemCard
          key={item.node.id}
          itemId={itemId}
          setCarousel={setCarousel}
          carousel={true}
          {...item.node}
        />
      );
    }) || [];

  /*
   * vipSearchItems
   * */
  const itemsVipList =
    (searchVippedItemsQueryData?.items &&
      searchVippedItemsQueryData.items.map(item => {
        return (
          <ItemCard
            key={item.id}
            itemId={itemId}
            setCarousel={setCarousel}
            carousel={true}
            {...item}
          />
        );
      })) ||
    [];

  /*
   * vipResidencesSearchItems
   * */
  const itemsVipResidencesList =
    (searchVippedResidencesItemsQueryData?.items &&
      searchVippedResidencesItemsQueryData.items.map(item => {
        return (
          <ItemCard
            key={item.id}
            itemId={itemId}
            setCarousel={setCarousel}
            carousel={true}
            {...item}
          />
        );
      })) ||
    [];

  const placeholder = loading => {
    if (!loading) return null;

    return (
      <SectionPlaceholder>
        <ItemCardPlaceholder />
        <ItemCardPlaceholder />
      </SectionPlaceholder>
    );
  };

  useEffect(() => {
    if (
      searchVippedItemsQueryError ||
      searchVippedResidencesItemsQueryError ||
      searchItemsError
    ) {
      setErrorUI(true);
    }
  });

  return (
    <>
      {(searchItemsQueryData &&
        !searchItemsQueryLoading &&
        !searchItemsQueryData.itemsConnection.totalCount &&
        emptyLayout) || (
        <>
          {!(searchItemsQueryLoading && !itemsList.length) ? (
            <SearchTools
              totalCount={searchItemsQueryData?.itemsConnection.totalCount}
              h1={h1}
              togglePopup={togglePopup}
              isPopupShown={isPopupShown}
            />
          ) : (
            <SearchToolsPlaceholder />
          )}
          {!searchVippedItemsQueryLoading && itemsVipList.length ? (
            <Section
              id="search-page-vipped"
              title={t('sections.vipped.title')}
              url="/items/vipped"
              urlTitle={t('sections.vipped.show_all')}
              sectionAdditionalClasses="section-block center-block"
            >
              <SectionList listAdditionalClasses="section-list_hide-excess-items">
                {itemsVipList}
              </SectionList>
            </Section>
          ) : (
            placeholder(searchVippedItemsQueryLoading)
          )}
          {!searchVippedResidencesItemsQueryLoading &&
          itemsVipResidencesList.length ? (
            <>
              {
                <Section
                  id="search-page-vipped-residences"
                  title={t('sections.vipped_residences.title')}
                  sectionAdditionalClasses="section-block center-block"
                >
                  <SectionList listAdditionalClasses="section-list_hide-excess-items">
                    {itemsVipResidencesList}
                  </SectionList>
                </Section>
              }
            </>
          ) : (
            placeholder(searchVippedResidencesItemsQueryLoading)
          )}
          {!(searchItemsQueryLoading && !itemsList.length) ? (
            <Section
              id="search-page-regular-items"
              title={t('search.ads')}
              sectionAdditionalClasses="section-block center-block"
            >
              <EndlessSearchItems
                items={itemsList || []}
                loading={searchItemsQueryLoading}
                ref={searchListRef}
                isStopLoad={
                  searchItemsQueryData &&
                  !searchItemsQueryData.itemsConnection.pageInfo.hasNextPage
                }
                onLoadMore={() => {
                  const moreSearchItemsQueryVariables = {
                    first: 16,
                    filter: transformedObj.filter,
                    sort: transformedObj.sorting || 'BUMPED_AT_DESC',
                    cursor:
                      searchItemsQueryData.itemsConnection.pageInfo.endCursor
                  };
                  fetchMore({
                    variables: moreSearchItemsQueryVariables
                  });
                }}
              >
                {itemsList}
              </EndlessSearchItems>
            </Section>
          ) : (
            <SectionPlaceholder>
              <ItemCardPlaceholder />
              <ItemCardPlaceholder />
            </SectionPlaceholder>
          )}
        </>
      )}
      <SearchPopup
        modalId="more-filters"
        closeHandler={togglePopup}
        resetHandler={togglePopup}
        isOpen={isPopupShown}
        popupTitle={t('search.filters.title')}
        inputPlaceholder={t('search.ads_search.placeholder')}
        reset={true}
        inputMode="numeric"
        additionalClass="search-popup--filters"
      >
        {(searchString, isFocused, cancelHandler, isSubmittedForm, setRef) => (
          <SearchFilters
            isFocused={isFocused}
            searchString={searchString}
            cancelHandler={cancelHandler}
            isSubmittedForm={isSubmittedForm}
            setRef={setRef}
            togglePopup={togglePopup}
            initialFormValues={convertValues(transformedObj.filter)}
            sorting={transformedObj.sorting}
          />
        )}
      </SearchPopup>
    </>
  );
};

SearchPage.propTypes = {
  transformedObj: PropTypes.object,
  isEmptySearch: PropTypes.bool,
  searchError: PropTypes.array,
  h1: PropTypes.string,
  setErrorUI: PropTypes.func
};

export default connect(null, mapDispatchToProps)(SearchPage);
